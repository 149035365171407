//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            isHome: false
        }
    },
    props: [
        'content'
    ],
    computed: {
        loc() {
            return this.$store.state.location
        },
    },
    beforeMount() {
        if (this.$route.path === '/') {
            this.isHome = true
        }
    },
}
